import { deepObjectValue } from '@/helpers/object';
import { i18n } from '@/helpers/i18n';

const documentFieldTypes = {
  CHECKBOX: 'CHECKBOX',
  CHECKBOX_INVERTED: 'CHECKBOX_INVERTED',
  TEXT: 'TEXT',
  INTEGER: 'INTEGER',
  DECIMAL: 'DECIMAL',
  ARRAY: 'ARRAY',
  DATE: 'DATE',
  SPECIAL: 'SPECIAL', // resolve by name what to do
};

/**
 * A DocumentField defines the position and formatting of a data field
 * in an exported document (e.g. PDF). The field value is resolved from
 * an outputData object (e.g. a project) by its name and then formatted
 * according to its type.
 */
export class DocumentField {
  constructor(field, outputData) {
    this.outputData = outputData;
    this.field = field;
    this.resolvedField = null;
  }

  /**
   *
   */
  resolve() {
    this.resolvedField = deepObjectValue(this.outputData, this.field.name);
    console.log(this.field.key, this.field.name, this.field.type, this.resolvedField);
  }

  /**
   * Format the output according to the field type
   * @returns
   */
  format() {
    /** Special */
    if (this.field.type === documentFieldTypes.SPECIAL) {
      return this.formatSpecial();
    }

    /** Multiselect Checkbox */
    if (this.resolvedField instanceof Array && this.field.type === documentFieldTypes.CHECKBOX) {
      return this.formatMultivalueCheckbox();
    }

    /** Checkbox */
    if (this.field.type === documentFieldTypes.CHECKBOX) {
      return this.formatCheckbox();
    }

    /** Checkbox */
    if (this.field.type === documentFieldTypes.CHECKBOX_INVERTED) {
      return this.formatCheckboxInverted();
    }

    /** Checkbox */
    if (this.field.type === documentFieldTypes.DATE) {
      return this.formatDate();
    }

    /** Tranlate value with i18n*/
    if (this.field.translate) {
      return i18n.t(this.resolvedField);
    }

    /** Array: translate and join */
    if (this.field.type === documentFieldTypes.ARRAY && this.resolvedField.length >= 1) {
      return this.resolvedField
        .map((element) => {
          return i18n.t(element);
        })
        .join(', ');
    }

    /** Decimal number format */
    if (typeof this.resolvedField === 'number' && this.field.type !== documentFieldTypes.INTEGER) {
      return i18n.n(this.resolvedField, 'decimal_triple');
    }

    /** Decimal integer format */
    if (typeof this.resolvedField === 'number' && this.field.type === documentFieldTypes.INTEGER) {
      return parseInt(this.resolvedField);
    }

    /** return unformatted value */
    return this.resolvedField;
  }

  /**
   * if output should be a checked box return 'x' only if the fields value
   * matches the value in the output data
   * @returns
   */
  formatCheckbox() {
    return this.resolvedField === this.field.value ? 'x' : '';
  }

  formatCheckboxInverted() {
    return this.resolvedField !== this.field.value ? 'x' : '';
  }

  formatDate() {
    // console.log('formatDate()', this.resolvedField);
    return this.resolvedField;
  }

  /**
   * if the outputData has multiple selected values search the array
   * if it contains the field value
   * @returns
   */
  formatMultivalueCheckbox() {
    for (const value of this.resolvedField) {
      if (value === this.field.value) return 'x';
    }
    return '';
  }

  formatSpecial() {
    if (this.field.key === 'smartCheckboxes.netOutputStates_one_phase') {
      return this.formatNetOutputStatesOnePhase();
    }
    if (this.field.key === 'smartCheckboxes.netOutputStates_two_phases') {
      return this.formatNetOutputStatesTwoPhases();
    }
    if (this.field.key === 'smartCheckboxes.netOutputStates_three_phases') {
      return this.formatNetOutputStatesThreePhases();
    }
    if (this.field.key === 'smartCheckboxes.netOutputStates_three_phases_or_threeway_current') {
      return this.formatNetOutputStatesThreePhasesOrThreewayCurrent();
    }
  }

  /** one phase if value is one of L1,L2 or L3 */
  formatNetOutputStatesOnePhase() {
    if (this.resolvedField.length !== 1) return '';

    if (
      this.resolvedField[0] === 'L1' ||
      this.resolvedField[0] === 'L2' ||
      this.resolvedField[0] === 'L3'
    )
      return 'x';
  }

  /** two phases if selected values are one of L1 and L2 or L2,L3 or L1,L3 */
  formatNetOutputStatesTwoPhases() {
    if (this.resolvedField.length === 2) return 'x';
    return '';
  }

  /** three phases if selected values are L1,L2 and L3 */
  formatNetOutputStatesThreePhases() {
    if (this.resolvedField.length === 3) return 'x';
    return '';
  }

  /** three phases if selected values are L1,L2 and L3 or THREEWAY_CURRENT */
  formatNetOutputStatesThreePhasesOrThreewayCurrent() {
    if (this.resolvedField.find((state) => state === 'THREEWAY_CURRENT')) {
      return 'x';
    }
    if (this.resolvedField.length === 3) {
      return 'x';
    }
    return '';
  }
}
