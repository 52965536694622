<template>
  <PrintPage :style="backgroundStyles(image)" :showPageNumber="false">
    <template v-for="(field, index) in fields" class="fields">
      <div v-if="isImage(field.name)" class="field" :key="index" :style="fieldPositionStyle(field)">
        <img :src="resolveField(field)" />
      </div>
      <div
        v-else-if="isFreeText(field)"
        :key="index"
        class="field"
        :style="fieldPositionStyle(field)"
      >
        {{ field.value }}
      </div>
      <div
        v-else
        class="field"
        :class="{ dragCheckbox: isCheckbox(field) }"
        :key="index"
        :style="fieldPositionStyle(field)"
      >
        {{ resolveField(field) }}
      </div>
    </template>
  </PrintPage>
</template>
<script>
import { DocumentField } from '@/helpers/documentField';
import PrintPage from '@/components/Print/PrintPage';

export default {
  name: 'DocumentPrintPage',
  components: { PrintPage },
  props: {
    fields: Array,
    image: String,
    project: Object,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    isCheckbox(field) {
      if (
        field.name === 'common.selection' ||
        field.type === 'CHECKBOX' ||
        field.type === 'CHECKBOX_INVERTED' ||
        field.type === 'SPECIAL'
      ) {
        return true;
      }
      return false;
    },
    /**
     * Fields of type freeText show their value instead being the value from resolveField()
     */
    isFreeText(field) {
      return field.name === 'common.freeText' ? true : false;
    },
    isImage(path) {
      return (
        path === 'common.signature' ||
        path === 'electrician.signature' ||
        path === 'electrician.stamp'
      );
    },
    /**
     * Search the given path in the project object and return a formatted value
     */
    resolveField(field) {
      const documentField = new DocumentField(field, this.project);
      documentField.resolve();
      return documentField.format();
    },
    backgroundStyles(image) {
      return {
        'background-image': `url(${image})`,
      };
    },
    fieldPositionStyle(field) {
      const style = {
        top: `${field.y}px`,
        left: `${field.x}px`,
        width: `${field.width}px`,
        height: `${field.height}px`,
      };

      // if(image) {
      //   style['background-image'] = `url(${image})`:
      // }

      return style;
    },
  },
  async mounted() {},
};
</script>

<style lang="scss" scoped>
.field {
  position: absolute;
  // line-height: 12px;
  // border: 1px solid red;
  font-size: 11px;
  padding: 2px 0 0 4px;
}
.dragCheckbox {
  line-height: 8px;
  font-weight: bold;
  font-size: 14px;
  padding: 0;
}
.field img {
  max-width: 100%;
  max-height: 100%;
}
</style>
