<template>
  <div v-if="isProjectReady" class="document">
    <DocumentPrintPage
      v-for="(page, index) in pages"
      :key="page.id"
      :fields="getFieldsByPage(index)"
      :project="project"
      :image="page.path"
    />
  </div>
  <div v-else>Keine Daten vorhanden!</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DocumentPrintPage from '@/components/Document/DocumentPrintPage';
import { ExtendedProject } from '@/helpers/projects';

export default {
  name: 'ProjectDocumentPrintPage',
  components: {
    DocumentPrintPage,
  },
  props: {
    projectNumber: {
      required: true,
      default: null,
    },
    documentNumber: {
      required: true,
      default: null,
    },
  },
  computed: {
    ...mapGetters('auth', ['isAdmin']),
    ...mapGetters(['getProjectByNumber', 'getDocumentByNumber']),
    document() {
      return this.getDocumentByNumber(this.documentNumber);
    },
    project() {
      const project = this.getProjectByNumber(this.number);
      if (project) {
        new ExtendedProject(project);
      }
      return project;
    },
    pages() {
      return this.document.pageBackgroundAttachments;
    },
    /** Check prerequesites to generate pdf */
    isProjectReady() {
      return this.project && this.document && this.document.pageBackgroundAttachments.length > 0;
    },
  },
  methods: {
    ...mapActions(['fetchProjectByNumber', 'fetchDocumentByNumber']),
    getFieldsByPage(index) {
      const page = index + 1;
      return this.document.fields.filter((field) => field.page == page);
    },
  },
  async mounted() {
    this.number = this.projectNumber;
    await this.fetchProjectByNumber(this.number);
    await this.fetchDocumentByNumber(this.documentNumber);
  },
};
</script>
<style lang="scss" scoped>
.document {
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>
